<template>
    <component 
        :is="calendarComponent" 
        :uKey="uKey" 
        :related_object="related_object" 
        :addEventCheck="addEventCheck" />
</template>

<script>
export default {
    props: {
        uKey: {
            type: [String, Number],
            default: 'default'
        },
        related_object: {
            type: [String, Number],
            default: null
        },
        addEventCheck: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        calendarComponent() {
            if(this.isMobile)
                return () => import('./Mobile.vue')
            else
                return () => import('./Desktop.vue')
        }
    }
}
</script>